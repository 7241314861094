<template>
  <div class="form">
    <div class="wrapper illustration illustration_dots">
      <Backlink title="questionnaire" service="mortgage" backlink="/mortgage/expense" />
      <div class="content">
        <AboutExpenses service="mortgage" nextlink="/mortgage/obligations" />
      </div>
    </div>
  </div>
</template>

<script>
import Backlink from '@/components/base/Backlink.vue'
import AboutExpenses from '@/components/service/AboutExpenses.vue'

export default {
  name: 'Mortgage_about_expences',
  components: { Backlink, AboutExpenses }
}
</script>
